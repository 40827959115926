import React from 'react'
import Metadata from '../components/Metadata'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

const TemplateWrapper = ({ children, containerStyle, metadataDescription, metadataTitle, showLinks=true, showMandatoryLinksOnly=false }) => {
  return (
    <div>
      <Metadata description={metadataDescription} title={metadataTitle}/>
      <Navbar showLinks={showLinks} />
      <div css={{ paddingTop: '83px', ...containerStyle}}>
        {children}
      </div>
      <Footer showMandatoryLinksOnly={showMandatoryLinksOnly}/>
    </div>
  )
}

export default TemplateWrapper
