import React from 'react'
import CascadeLink from '../components/CascadeLink'
import logo from '../img/logo-nopad.svg'
import Button from '../components/Button'
import { themeStyles, colors, presets } from '../utils/theme'
import { parent } from 'glamor'
import { FaCaretUp } from 'react-icons/fa';

const styles = {
  nav: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `6px solid ${colors.shamrock}`,
    position: 'fixed',
    width: '100%',
    height: '83px',
    backgroundColor: colors.white,
    zIndex: 10,
  },
  logo: {
    maxHeight: '34px',
    marginBottom: '0px',
  },
  spacer: {
    flex: 1,
    display: 'flex',
  },
  callUs: {
    color: colors.shamrock,
    fontSize: '15px',
    textDecoration: 'none',
  },
  navItem: {
    marginLeft: '1.5em',
    fontSize: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'middle',
    textDecoration: 'none',
  },
  desktopOnly: {
    display: 'none',
    [presets.Desktop]: {
      display: 'inline-flex',
    }
  },
  nonDesktopOnly: {
    display: 'flex',
    [presets.Desktop]: {
      display: 'none',
    }
  },
  content: {
    padding: '0 15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...themeStyles.contentWidth,
  },
  dropdownContent: {
    position: 'absolute',
    left: '-50px',
    top: '48px',
    minWidth: '150px',
    zIndex: 1,
    display: 'none',
    ":hover": {
      display: 'block',
    },
  },
  dropdownLink: {
    padding: '10px 10px',
    display: 'block',
    color: colors.grey,
    textDecoration: 'none',
    ":hover": {
      color: colors.shamrock,
    },
    lineHeight: '1em',
  },
  dropdown: {
    position: 'relative',
    cursor: 'pointer',
    alignSelf: 'stretch',
    display: 'none',
    textDecoration: 'none',
    [presets.Tablet]: {
      display: 'block',
    },
  },
  titleContainer: {
    fontWeight: '600',
  }
}

const chevron = (
  <div css={{position: 'relative', width: '30px'}}>
    <FaCaretUp color={colors.green} css={{position: 'absolute', top: '-35px', left: '55px'}} size='50px'/>
    <FaCaretUp color={colors.white} css={{position: 'absolute', top: '-25px', left: '55px'}} size='50px'/>
  </div>
)
const MenuItem = ({ title, subMenus=[] }) => {
  const subMenuLinks = subMenus.map(item => {
    return <CascadeLink key={item.link} css={styles.dropdownLink} to={item.link}>{item.title}</CascadeLink>
  })
  return (
    <div key={title} css={styles.dropdown}>
      <div css={styles.titleContainer}>
        {title}
      </div>
      <div css={[styles.dropdownContent, parent('div:hover >', {display: 'block'})]}>
        <div css={{ ...themeStyles.boxShadow, marginTop: '20px', backgroundColor: 'white', position: 'relative'}}>
          {chevron}
          {subMenuLinks}
        </div>
      </div>
    </div>
  )
}

const Navbar = ({showLinks=true}) => {
  return (
    <nav css={styles.nav}>
      <div css={styles.content}>
        <CascadeLink to="/" title="Logo" >
          <img src={logo} alt="LoanSnap" css={styles.logo}/>
        </CascadeLink>

       {showLinks &&
        <React.Fragment>
          <div css={{...styles.navItem, lineHeight: '55px', ...styles.desktopOnly, color: colors.grey}}>
            <MenuItem
              title="Products"
              subMenus={[
                {link: '/refinance', title: 'Refinance'},
                {link: '/heloc', title: 'HELOC'},
                {link: '/mortgage', title: 'Mortgage'},
                {link: '/va-loans', title: 'VA Loans'},
              ]}
            />
          </div>

          <CascadeLink to="/about" css={{...styles.titleContainer, ...styles.navItem, lineHeight: '55px',  ...styles.desktopOnly, color: colors.grey}}>
            <span>About us</span>
          </CascadeLink>
          <div css={styles.spacer}/>
          <CascadeLink
            href="tel:949-236-5134"
            target="_blank"
            css={{...styles.callUs, ...styles.desktopOnly}}
          >
            Call us: 949-236-5134
          </CascadeLink>
          <Button
            href="https://secure.goloansnap.com/signin"
            secondary
            target="_blank"
            text="Sign In"
            style={{...styles.navItem, ...styles.desktopOnly}}
            analyticsTargetName='Header Sign In'
          />
          <Button
            href="https://secure.goloansnap.com"
            target="_blank"
            text="Get Started"
            style={{...styles.navItem, ...styles.desktopOnly}}
            analyticsTargetName='Header Get Started'
          />
          <Button
            href="tel:949-236-5134"
            target="_blank"
            text="Call us"
            secondary
            style={{...styles.navItem, ...styles.nonDesktopOnly, marginRight: '10px' }}
            analyticsTargetName='Header Call us'
          />
        </React.Fragment>}
      </div>
    </nav>
  )
}

export default Navbar
