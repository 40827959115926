import React, { Component } from 'react'
import CascadeLink from '../components/CascadeLink'
import { colors } from '../utils/theme'
import PropTypes from 'prop-types'

const buttonBase = {
  fontSize: '15px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '10px',
  width: '155px',
  height: '45px',
  color: 'white',
  transition: 'all 0.3s ease',
  fontWeight: 500,
  backgroundColor: colors.shamrock,
  ':hover': {
    color: 'white',
    backgroundColor: colors.shamrock,
  },
  textAlign: 'center',
  textDecoration: 'none',
}

export const styles = {
  primary: buttonBase,
  secondary: {
    ...buttonBase,
    backgroundColor: 'white',
    border: `2px solid ${colors.shamrock}`,
    color: colors.shamrock,
  },
  largeMixin: {
    fontSize: '22px',
    width: '240px',
    height: '60px',
  }
}

class Button extends Component {
  render() {
    const { text, style, secondary, size, analyticsTargetName, ...otherProps } = this.props
    const currentStyle = secondary ? styles.secondary : styles.primary
    const sizeStyle = size === 'lg' ? styles.largeMixin : {}
    return (
      <CascadeLink
        css={{...currentStyle, ...sizeStyle, ...style}}
        {...otherProps}
        analyticsTargetName={analyticsTargetName || text}
      >
        {this.props.text}
      </CascadeLink>
    )
  }
}

Button.propTypes = {
  analyticsTargetName: PropTypes.string
}

export default Button
