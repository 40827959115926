import React from 'react'
import { themeStyles, colors, presets } from '../utils/theme'
import CascadeLink from '../components/CascadeLink'

import bbb from '../img/bbb.svg'
import trustPilot from '../img/trustpilot.svg'
import equalHousing from '../img/equal-housing_white.svg'
import logo from '../img/icons/LoanSnap_Logo_white_no-padding.svg'

import { FaFacebookF, FaInstagram, FaYoutube, FaLinkedinIn, FaTwitter, FaPinterestP } from 'react-icons/fa';
import { MdPhone, MdEmail } from 'react-icons/md';

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    maxWidth: '300px',
    justifyContent: 'space-between',
  },
  iconOuterStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '5px',
    margin: '3px',
    backgroundColor: colors.mediumGreen,
  },
  footer: {
    backgroundColor: colors.darkGreen,
  },
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    ...themeStyles.contentWidth,
    ...themeStyles.textPadding,
    [presets.Tablet]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '200px',
    minWidth: '100px',
    color: colors.white,
    marginTop: '20px',
    [presets.Tablet]: {
      marginTop: 0,
    },
    fontWeight: 300,
    fontSize: '15px',
  },
  mandatoryColOnly: {
    maxWidth: '100%',
    minWidth: '100%',
    flexDirection: 'column',
    [presets.Tablet]: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    }
  },
  colTitle: {
    color: colors.green,
    fontWeight: 600,
    fontSize: '15px',
    marginBottom: '7px',
  },
  link: {
    color: colors.white,
    fontWeight: 100,
    marginBottom: '5px',
    textDecoration: 'none',
  },
  bold: {
    fontWeight: 'normal',
  },
  finePrint: {
    fontSize: '10px',
    textAlign: 'left',
    color: colors.mediumGreen,
    ...themeStyles.contentWidth,
    [presets.Tablet]: {
      paddingLeft: '40px',
      paddingRight: '40px',
    }
  },
  trustHolders: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '20px 0',
    [presets.Tablet]: {
      margin: 0,
      marginLeft: '30px',
      alignItems: 'center',
      justifyContent: 'center',
    }
  },
  logo: {
    width: '200px',
    [presets.Tablet]: {
     margin: 0,
    }
  },
  bottomContainer: {
    borderTop: `1px solid ${colors.mediumGreen}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    ...themeStyles.contentWidth,
    ...themeStyles.textPadding,
    [presets.Tablet]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
}

const CURRENT_YEAR = new Date().getFullYear()

const SocialButtons = () => {
  return (
    <div css={styles.mainContainer}>
      <CascadeLink analyticsTargetName='Social Facebook' target="_blank" rel="noopener noreferrer" href='https://facebook.com/loansnap' style={styles.iconOuterStyle}><FaFacebookF color={colors.darkGreen} size="20" /></CascadeLink>
      <CascadeLink analyticsTargetName='Social YouTube' target="_blank" rel="noopener noreferrer" href='https://www.youtube.com/channel/UCyJsH6gD7m4J3rf0Oyqzelw' style={styles.iconOuterStyle}><FaYoutube color={colors.darkGreen} size="22"  /></CascadeLink>
      <CascadeLink analyticsTargetName='Social LinkedIn' target="_blank" rel="noopener noreferrer" href='https://www.linkedin.com/company/loansnap/' style={styles.iconOuterStyle}><FaLinkedinIn color={colors.darkGreen} size="20"  /></CascadeLink>
      <CascadeLink analyticsTargetName='Social Twitter' target="_blank" rel="noopener noreferrer" href='https://twitter.com/loansnap' style={styles.iconOuterStyle}><FaTwitter color={colors.darkGreen} size="20" /></CascadeLink>
      <CascadeLink analyticsTargetName='Social Instagram' target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/loansnap/' style={styles.iconOuterStyle}><FaInstagram color={colors.darkGreen} size="20"  /></CascadeLink>
      <CascadeLink analyticsTargetName='Social Pinterest' target="_blank" rel="noopener noreferrer" href='https://www.pinterest.com/loansnap/' style={styles.iconOuterStyle}><FaPinterestP color={colors.darkGreen} size="20"  /></CascadeLink>
    </div>
  )
}

const Footer = class extends React.Component {
  constructor(props) {
    super(props)
  }
  showMandatoryLinksOnly = this.props.showMandatoryLinksOnly || false
  render() {
    return (
      <footer css={styles.footer}>
        <div css={styles.outerContainer}>
          {!this.showMandatoryLinksOnly && <div css={styles.col}>
            <h3 css={styles.colTitle}>
              Company
            </h3>
            <CascadeLink
              to="/about"
              css={styles.link}
              analyticsTargetName='Footer About'
            >
              About us
            </CascadeLink>
            <CascadeLink
              to="/faq"
              css={styles.link}
              analyticsTargetName='Footer FAQ'
            >
              FAQ
            </CascadeLink>
            <CascadeLink
              to="/blog"
              css={styles.link}
              analyticsTargetName='Footer Blog'
            >
              Blog
            </CascadeLink>
            <CascadeLink
              to="/press"
              css={styles.link}
              analyticsTargetName='Footer Press'
            >
              Press
            </CascadeLink>
            <CascadeLink
              to="/careers"
              css={styles.link}
              analyticsTargetName='Footer Careers'
            >
              Careers
            </CascadeLink>
            <CascadeLink
              href="https://loansnap.org"
              css={styles.link}
              analyticsTargetName='Footer Foundation'
            >
              Foundation
            </CascadeLink>
            <CascadeLink
              href="https://secure.goloansnap.com/signin"
              css={styles.link}
              analyticsTargetName='Footer Sign in'
            >
              Sign in
            </CascadeLink>
          </div>}
          {!this.showMandatoryLinksOnly && <div css={styles.col}>
            <h3 css={styles.colTitle}>
              Products
            </h3>
            <CascadeLink
              to="/refinance"
              css={styles.link}
              analyticsTargetName='Footer Refinance'
            >
              Refinance
            </CascadeLink>
            <CascadeLink
              to="/heloc"
              css={styles.link}
              analyticsTargetName='Footer HELOC'
            >
              HELOC
            </CascadeLink>
            <CascadeLink
              to="/mortgage"
              css={styles.link}
              analyticsTargetName='Footer Mortgage'
            >
              Mortgage
            </CascadeLink>
            <CascadeLink
              to="/va-loans"
              css={styles.link}
              analyticsTargetName='Footer VA Loans'
            >
              VA Loans
            </CascadeLink>
            <CascadeLink
              to="/get-your-license/"
              css={styles.link}
              analyticsTargetName='Footer Get Your License'
            >
              Get Your License
            </CascadeLink>
          </div>}
          <div css={[styles.col, this.showMandatoryLinksOnly&&styles.mandatoryColOnly]}>
            {!this.showMandatoryLinksOnly && <h3 css={styles.colTitle}>
              Legal
            </h3>}
            <CascadeLink
              to="/legal/licenses-and-disclosures"
              css={styles.link}
              analyticsTargetName='Footer Licenses and Disclosures'
            >
              Licenses
            </CascadeLink>
            <CascadeLink
              to="/legal/terms-and-conditions"
              css={styles.link}
              analyticsTargetName='Footer Terms and Conditions'
            >
              Terms
            </CascadeLink>
            <CascadeLink
              to="/legal/privacy-policy"
              css={styles.link}
              analyticsTargetName='Footer Privacy Policy'
            >
              Privacy Policy
            </CascadeLink>
          </div>
          {!this.showMandatoryLinksOnly && <div css={styles.col}>
            <h3 css={styles.colTitle}>
              Our locations
            </h3>
            <div>
              <div css={styles.bold}>Lending Locations</div>
              <div>3070 Bristol Street #200<br/> Costa Mesa<br/> CA 92626</div>
            </div>
          </div>}
          {!this.showMandatoryLinksOnly && <div css={styles.col}>
            <h3 css={styles.colTitle}>
              Contact us
            </h3>
            <CascadeLink
              href="mailto:info@goloansnap"
              css={styles.link}
              analyticsTargetName='Footer Email Link'
            >
              <MdEmail css={{ marginBottom: -2, marginRight: '5px', }} color="white"/> info@goloansnap.com
            </CascadeLink>
            <CascadeLink
              href="tel:+19492367068"
              css={styles.link}
              analyticsTargetName='Footer Phone Link'
            >
              <MdPhone color="white" css={{ marginBottom: -2, marginRight: '5px', }}/> 949-236-7068
            </CascadeLink>
            <SocialButtons />
          </div>}
        </div>
        <div css={styles.bottomContainer}>
          <CascadeLink
            to="/"
            title="Logo"
            analyticsTargetName='Footer Logo Link'
          >
            <img src={logo} alt="LoanSnap" css={styles.logo}/>
          </CascadeLink>
          <div css={styles.finePrint}>
            © {CURRENT_YEAR} LoanSnap, Inc. All rights reserved. LoanSnap, Inc. is a direct lender. NMLS #76967 | <CascadeLink href="https://www.nmlsconsumeraccess.org/" analyticsTargetName="Footer NMLS" css={{color: colors.white, textDecoration: 'none'}}> NMLS access page </CascadeLink>
            Lending services provided by LoanSnap, Inc., a subsidiary of LoanSnap Holdings, Inc. Note: any references to LoanSnap on this website correspond to the legal entity LoanSnap, Inc.
          </div>
          <div css={styles.trustHolders}>
            <img src={equalHousing} css={{ width: '30px', margin: 0}} alt='equal housing'/>
            <img src={bbb} css={{ width: '60px', margin: 0, marginLeft: '30px'}} alt='BBB'/>
            <img src={trustPilot} css={{ width: '100px', margin: 0, marginLeft: '30px'}} alt='TrustPilot'/>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
